import React from "react";
import { useNavigate } from 'react-router-dom';
/* Styles */
import './RoutingModule.scss';
/* Images */
import galleryImage from '../../utils/images/components/Galeria.png';
import videosImage from '../../utils/images/components/Videos.png';

const RoutingModule: React.FC = () => {
    const navigate = useNavigate();
    
    const goTo : (view: string) => void = (view) => {
        navigate(`/${view}`);
    };

    return (
        <section className="container-routing">
            <div className="container-content">
                <div className="background"></div>
                <div onClick={() => goTo('emisiones')} className="sign-emissions">
                    <h2>EMISIONES</h2>
                </div>
                <div className="emissions-content">
                    <article className="article-container">
                        <div className="article-item-1"> 
                            <p>1er Temporada</p>
                            <ul>
                                <li>01/11/2021</li>
                                <li>02/11/2021</li>
                                <li>03/11/2021</li>
                                <li>05/11/2021</li>
                                <li>06/11/2021</li>
                                <li>07/11/2021</li>
                            </ul>
                        </div>
                        <div className="article-item-2"> 
                            <p>2da Temporada</p>
                            <ul>
                                <li>15/02/2022</li>
                                <li>25/02/2022</li>
                                <li>26/02/2022</li>
                                <li>04/02/2022</li>
                                <li>05/02/2022</li>
                            </ul>
                        </div>
                        <div className="article-item-3"> 
                            <p>3ra Temporada</p>
                            <ul>
                                <li>00/00/202X</li>
                                <li>00/00/202X</li>
                                <li>00/00/202X</li>
                                <li>00/00/202X</li>
                                <li>00/00/202X</li>
                                <li>00/00/202X</li>
                            </ul>
                        </div>
                    </article>
                </div>
                <div onClick={() => goTo('galeria')} className="sign-gallery">
                    <h2>GALERIA</h2>
                </div>
                <div className="gallery-content">
                    <img src={galleryImage} alt="Gallery" />
                </div>
                <div onClick={() => goTo('videos')} className="sign-videos">
                    <h2>VIDEOS</h2>
                </div>
                <div className="videos-content">
                    <img src={videosImage} alt="Videos" />
                </div>
                <div onClick={() => goTo('somos')} className="sign-are">
                    <h2>SOMOS</h2>
                </div>
                <div className="are-content">
                    <article>
                        <p> 
                            Periferia Producciones es una productora audiovisual, 
                            organización de promoción social y artística que 
                            nace bajo las instalaciones de la casa mayaelo en 
                            Ciudad Bolívar como un ejercicio de impulso de lo 
                            que denominamos “Estéticas amarradas con 
                            alambres “. cómo construir comunidades a través del 
                            arte y la pedagogía, en este ejercicio hemos 
                            realizado acciones artísticas, encuentros 
                            pedagógicos, que permiten un intercambio de 
                            saberes en el marco de las otras estéticas.
                        </p>
                    </article>
                </div>
            </div>
        </section>
    );
} 

export default RoutingModule;