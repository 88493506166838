import React from 'react';
// Styles
import './Emissions.scss';
// Components
import EmissionsAudio from '../../components/EmissionsAudios/EmissionsAudios';
// Data
import { audios } from '../../utils/data/EmissionsData';
import { names } from '../../utils/data/EmissionsData';
import { images } from '../../utils/data/EmissionsData';

const Emissions: React.FC = () => {
  return (
    <section className="emissions-container">
      <div className='title-emissions'>
        <h1>
          EMISIONES
        </h1>
      </div>
      <EmissionsAudio audio={audios} name={names} image={images} />
    </section>
  );
};

export default Emissions;