import React from "react";
// Data
import EmissionsModel from "../../types/EmissionsModel";
// Styles
import './EmissionsAudio.scss';

const EmissionsAudio: React.FC<EmissionsModel> = ({ name, image, audio }) => {
    return (
        <section className="audiomultiplayer-container">
            {name.map((n, index) => (
                <div className="audio-section" key={index}>
                    <p className="tile-audio">Entrevista: {n}</p>
                    <img className="audio-image" src={image[index]} alt={`Imagen de la entrevista ${index + 1}`} />
                    <audio className="audio" src={audio[index]} controls>
                        Tu navegador no soporta el elemento de audio.
                    </audio>
                </div>
            ))}
        </section>
    );
};

export default EmissionsAudio;