import React from 'react';

// Styles
import './Home.scss';
import RoutingModule from '../../components/RoutingModule/RoutingModule';
import SliderComponent from '../../components/SliderComponent/SliderComponent';

const Home: React.FC = () => {
  return (
    <section className="home-container">
        <SliderComponent />
        <RoutingModule />
    </section>
  );
};

export default Home;
