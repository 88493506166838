import interview_1 from '../images/components/interview_1.jpg';
import interview_2 from '../images/components/interview_2.jpg';
import interview_3 from '../images/components/interview_3.jpg';
import interview_4 from '../images/components/interview_4.jpg';
import interview_5 from '../images/components/interview_5.jpg';
import interview_6 from '../images/components/interview_6.jpg';
import interview_7 from '../images/components/interview_7.jpg';
import interview_8 from '../images/components/interview_8.jpg';

export const audios: string[] = [
    `${process.env.PUBLIC_URL}/audios/EMISORA_LA_TERRAZA_Blanca_Pineda.mp3`,
    `${process.env.PUBLIC_URL}/audios/EMISORA_LA_TERRAZA_Felipe_Avila.mp3`,
    `${process.env.PUBLIC_URL}/audios/EMISORA_LA_TERRAZA_Siendo_Franco.mp3`,
    `${process.env.PUBLIC_URL}/audios/EMISORA_LA_TERRAZA_Semillas_Creativas.mp3`,
    `${process.env.PUBLIC_URL}/audios/EMISORA_LA_TERRAZA_Masai_Ban-Go.mp3`,
    `${process.env.PUBLIC_URL}/audios/EMISORA_LA_TERRAZA_Fenomenos_Crew.mp3`,
    `${process.env.PUBLIC_URL}/audios/EMISORA_LA_TERRAZA_Maria_Mora.mp3`,
    `${process.env.PUBLIC_URL}/audios/EMISORA_LA_TERRAZA_Crew.mp3`
];


export const names: string[] = [
    "Blanca Pineda",
    "Felipe Avila",
    "Siendo Franco",
    "Semillas Creativas",
    "Masai Ban-Go",
    "Fenomenos Crew",
    "Maria Mora",
    "Crew"
];

export const images: any[] = [
    interview_1,
    interview_2,
    interview_3,
    interview_4,
    interview_5,
    interview_6,
    interview_7,
    interview_8
];