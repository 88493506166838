import React from "react";
import { useNavigate } from "react-router-dom";
// Styles
import './SliderComponent.scss';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
// Images
import matera from '../../utils/images/components/Maseta_con_Madeja.png';
import sing from '../../utils/images/components/Letrero_de_Madera.png';
import plants from '../../utils/images/components/Maticas.png';
import guessChair from '../../utils/images/components/Silla_Entrevistados.png';
import chair from '../../utils/images/components/Silla_Jhonny.png';
import record from '../../utils/images/components/Grabadora.png';
import boot from '../../utils/images/components/Bota_Campeche.png';
import background from '../../utils/images/components/BackgroundCarousel.png';
import megaphone from '../../utils/images/components/Megafono.png';
import antenna from '../../utils/images/components/Antena.png';
import guitar from '../../utils/images/components/Guitarra.png';

const SliderComponent: React.FC = () => {

    const navigate = useNavigate();
    
    const goTo : (view: string) => void = (view) => {
        navigate(`/${view}`);
    };
    const renderTooltip = (props: any,  text: string ) => (
        <Tooltip id="button-tooltip" {...props}>
            {text}
        </Tooltip>
    );

    return  (
        <section className="container-carousel">
            <div className="container-carousel-background">
                <img src={background} alt="Background"  className="background-image"/>
                <div className="antenna">
                    <img src={antenna} alt="Antena" className="image-antenna" />
                </div>
                <div className="matera">
                    <img src={matera} alt="Matera" className="image-matera" />
                </div>
                <div className="sing">
                    <img src={sing} alt="Letrero" className="image-sing" />
                </div>
                <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip({ id: "tooltip" }, "Somos")}
                    >
                    <div className="plants" onClick={() => goTo('somos')}>
                        <img src={plants} alt="Plantas" className="image-plants" />
                    </div>
                </OverlayTrigger>
                <OverlayTrigger
                    placement="right"
                    delay={{ show: 150, hide: 400 }}
                    overlay={renderTooltip({ id: "tooltip" }, "Videos")}
                    >
                    <div className="guessChair" onClick={() => goTo('videos')}>
                        <img src={guessChair} alt="Silla Entrevistados" className="image-guessChair" />
                    </div>
                </OverlayTrigger>
                <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip({ id: "tooltip" }, "Galeria")}
                    >
                <div className="chair" onClick={() => goTo('galeria')}>
                    <img src={chair} alt="Silla" className="image-chair" />
                </div>
                </OverlayTrigger>
                <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip({ id: "tooltip" }, "Emisiones")}
                    >
                    <div className="record" onClick={() => goTo('emisiones')}>
                        <img src={record} alt="Grabadora" className="image-record" />
                    </div>
                </OverlayTrigger>
                <div className="magaphone">
                    <img src={megaphone} alt="Megafono" className="image-magaphone" />
                </div>
                <div className="guitar">
                    <img src={guitar} alt="Guitarra" className="image-guitar" />
                </div>
                <div className="boot">
                    <img src={boot} alt="Bota" className="image-boot" />
                </div>
            </div>  
        </section>
    )
}
export default SliderComponent;