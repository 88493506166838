export const titlesSeasonSecond: string[] = [
    "La Terraza Segunda Temporada",
    "Capitulo 1: Blanca Pineda",
    "Capitulo 2: Felipe Avila",
    "Capitulo 3: Siendo Franco",
    "Capitulo 4: Semillas Creativas",
    "Capitulo 5: Masai Ban-Go",
    "Capitulo 6: Fenomenos Crew",
    "Capitulo 7: Maria Mora",
    "Capitulo 8: OVC Crew"
];

export const titlesSeasonThree: string[] = [
    "La Terraza Tercera Temporada",
];

export const descriptionSeasonSecond: string[] = [
    "Iniciativa ganadora de la CONVOCATORIA ES CULTURA LOCAL PARA EL FORTALECIMIENTO DE INICIATIVAS ARTÍSTICAS Y CULTURALES DE LA LOCALIDAD DE CIUDAD BOLÍVAR 2021.",
    'Arrancamos esta segunda temporada con una de las mujeres mas berracas de nuestra localidad, con mas de 19 publicaciones, mas de 30 años de investigación, activismo, lucha y procesos comunitarios, ella es la señora Blanca Pineda "la historiadora de Ciudad Bolívar".',
    "En el día de hoy desde la terraza de la esquina del puente estaremos con el artista Audiovisual FELIPE AVILA, quien nos dará un resumen de como es hacer un cine recursivo en Ciudad Bolívar, el camaleón del cine valiente expone sus mas de 10 proyectos entre los cuales destacan 3 largometrajes rodados en nuestra localidad.",
    "Hoy estaremos con la agrupación de rap reggae de la localidad SIENDO FRANCO, artista que ha tenido un arduo trabajo musical y artístico en la localidad, siendo un referente en Rap a nivel Distrital.",
    "Hoy estamos con Semillas Creativas un colectivo que ha trabajado desde los años 80 en el barrio Juan pablo II, haciendo procesos apartir del arte, la memoria y la educaciòn.",
    "hoy estaremos con una de las agrupaciones de Hip Hop mas legendarias de la localidad de Ciudad Bolívar, artista que ha tenido un arduo trabajo musical y artístico en la localidad, siendo un referente en Rap a nivel Distrital.",
    "Magazin Radio la terraza beneficiarios de CONVOCATORIA ES CULTURA LOCAL PARA EL FORTALECIMIENTO DE INICIATIVAS ARTÍSTICAS Y CULTURALES DE LA LOCALIDAD DE CIUDAD BOLÍVAR 2021.",
    "Hoy estamos con una de las mayoras de la localidad 19 de ciudad Bolívar, madre, artesana, líder y inspiración para que hoy en día muchas mujeres sean productivas y productoras, este capitulo esta cargado de la historia de una lucha de muchas luchas.",
    "Hoy estamos con una de las escuelas de Break Dance mas emblemáticas no solo de Ciudad Bolívar sino que también es un gran referente Distrital."
];

export const descriptionSeasonThree: string[] = [
    "",
]

export const iframeSeasonSecond: React.IframeHTMLAttributes<HTMLIFrameElement>[] = [
    {
        width: "1037",
        height: "640",
        src: "https://www.youtube.com/embed/X6m-ueK3x2k?list=PLHze7tSOUFVxItZFKE4Kn5dm13xgrmxKC",
        title: "La Terraza Segunda Temporada",
        frameBorder: "0",
        allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
        referrerPolicy: "strict-origin-when-cross-origin",
        allowFullScreen: true,
    },
    {
        width: "1037",
        height: "640",
        src: "https://www.youtube.com/embed/kIPGT4GW7EE?list=PLHze7tSOUFVxItZFKE4Kn5dm13xgrmxKC",
        title:"EMISORA LA TERRAZA - T2- C1: Blanca Pineda",
        frameBorder: "0",
        allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
        referrerPolicy: "strict-origin-when-cross-origin",
        allowFullScreen: true,
    },
    {
        width: "1037",
        height: "640",
        src: "https://www.youtube.com/embed/zqkEXe7Uy9I?list=PLHze7tSOUFVxItZFKE4Kn5dm13xgrmxKC",
        title: "&quot;EMISORA LA TERRAZA&quot;  T:2 C2: Felipe Avila",
        frameBorder: "0",
        allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
        referrerPolicy: "strict-origin-when-cross-origin",
        allowFullScreen: true,
    },
    {
        width: "1037",
        height: "640",
        src: "https://www.youtube.com/embed/r2ChEce0kyk?list=PLHze7tSOUFVxItZFKE4Kn5dm13xgrmxKC",
        title: "EMISORA LA TERRAZA -T2 C3 Siendo Franco",
        frameBorder: "0",
        allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
        referrerPolicy: "strict-origin-when-cross-origin",
        allowFullScreen: true,
    },
    {
        width: "1037",
        height: "640",
        src: "https://www.youtube.com/embed/-bJ0liQbVs8?list=PLHze7tSOUFVxItZFKE4Kn5dm13xgrmxKC",
        title: "EMISORA LA TERRAZA - T2 C4 Semillas Creativas",
        frameBorder: "0",
        allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
        referrerPolicy: "strict-origin-when-cross-origin",
        allowFullScreen: true,
    },
    {
        width: "1037",
        height: "640",
        src: "https://www.youtube.com/embed/X8Na-lJ1OQI?list=PLHze7tSOUFVxItZFKE4Kn5dm13xgrmxKC",
        title: "EMISORA LA TERRAZA -T2 - C5 Masai Ban-Go",
        frameBorder: "0",
        allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
        referrerPolicy: "strict-origin-when-cross-origin",
        allowFullScreen: true,
    },
    {
        width: "1037",
        height: "640",
        src: "https://www.youtube.com/embed/hNlT18nD030?list=PLHze7tSOUFVxItZFKE4Kn5dm13xgrmxKC",
        title: "EMISO LA TERRAZA T2 C6 Fenomenos Crew",
        frameBorder: "0",
        allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
        referrerPolicy: "strict-origin-when-cross-origin",
        allowFullScreen: true,
    },
    {
        width: "1037",
        height: "640",
        src: "https://www.youtube.com/embed/v2JYLMHEibQ?list=PLHze7tSOUFVxItZFKE4Kn5dm13xgrmxKC",
        title: "EMISORA LA TERRAZA - T2- C7: María Mora",
        frameBorder: "0",
        allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
        referrerPolicy: "strict-origin-when-cross-origin",
        allowFullScreen: true,
    },
    {
        width: "1037",
        height: "640",
        src: "https://www.youtube.com/embed/fZpbw-gNohU?list=PLHze7tSOUFVxItZFKE4Kn5dm13xgrmxKC",
        title: "EMISORA LA TERRAZA - T2- C8 OVC Crew",
        frameBorder: "0",
        allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
        referrerPolicy: "strict-origin-when-cross-origin",
        allowFullScreen: true,
    }
]

export const iframeSeasonThree: React.IframeHTMLAttributes<HTMLIFrameElement>[] = [
    {
        width: "1037",
        height: "640",
        src: "https://www.youtube.com/embed/oouPj30cptA",
        title: "Tercera temporada de emisora &quot;La Terraza&quot;",
        frameBorder: "0",
        allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
        referrerPolicy: "strict-origin-when-cross-origin",
        allowFullScreen: true,
    }
];