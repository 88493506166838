import image_1 from "../images/components/1.jpg";
import image_2 from "../images/components/2.jpg";
import image_3 from "../images/components/3.jpg";
import image_4 from "../images/components/4.jpg";
import image_5 from "../images/components/5.jpg";
import image_6 from "../images/components/6.jpg";
import image_7 from "../images/components/7.jpg";
import image_8 from "../images/components/8.jpg";
import image_9 from "../images/components/9.jpg";
import image_10 from "../images/components/10.jpg";
import image_11 from "../images/components/11.jpg";
import image_12 from "../images/components/12.jpg";
import image_13 from "../images/components/13.jpg";
import image_14 from "../images/components/14.jpg";
import image_15 from "../images/components/15.jpg";
import image_16 from "../images/components/16.jpg";
import image_17 from "../images/components/17.jpg";
import image_18 from "../images/components/18.jpg";
import image_19 from "../images/components/19.jpg";
import image_20 from "../images/components/20.jpg";
import image_21 from "../images/components/21.jpg";
import image_22 from "../images/components/22.jpg";
import image_23 from "../images/components/23.jpg";
import image_24 from "../images/components/24.jpg";
import image_25 from "../images/components/25.jpg";
import image_26 from "../images/components/26.jpg";

import image_1_2 from "../images/components/1-2.jpg";
import image_2_2 from "../images/components/2-2.jpg";
import image_3_2 from "../images/components/3-2.jpg";
import image_4_2 from "../images/components/4-2.jpg";
import image_5_2 from "../images/components/5-2.jpg";
import image_6_2 from "../images/components/6-2.jpg";
import image_7_2 from "../images/components/7-2.jpg";
import image_8_2 from "../images/components/8-2.jpg";
import image_9_2 from "../images/components/9-2.jpg";
import image_10_2 from "../images/components/10-2.jpg";
import image_11_2 from "../images/components/11-2.jpg";
import image_12_2 from "../images/components/12-2.jpg";
import image_13_2 from "../images/components/13-2.jpg";
import image_14_2 from "../images/components/14-2.jpg";
import image_15_2 from "../images/components/15-2.jpg";
import image_16_2 from "../images/components/16-2.jpg";
import image_17_2 from "../images/components/17-2.jpg";
import image_18_2 from "../images/components/18-2.jpg";
import image_19_2 from "../images/components/19-2.jpg";
import image_20_2 from "../images/components/20-2.jpg";
import image_21_2 from "../images/components/21-2.jpg";
import image_22_2 from "../images/components/22-2.jpg";
import image_23_2 from "../images/components/23-2.jpg";
import image_24_2 from "../images/components/24-2.jpg";
import image_25_2 from "../images/components/25-2.jpg";
import image_26_2 from "../images/components/26-2.jpg";
import image_27_2 from "../images/components/27-2.jpg";
import image_28_2 from "../images/components/28-2.jpg";

export const PrimeraTemporada: any[] = [
    image_1,
    image_2,
    image_3,
    image_4,
    image_5,
    image_6,
    image_7,
    image_8,
    image_9,
    image_10,
    image_11,
    image_12,
    image_13,
    image_14,
    image_15,
    image_16,
    image_17,
    image_18,
    image_19,
    image_20,
    image_21,
    image_22,
    image_23,
    image_24,
    image_25,
    image_26
];


export const SegundaTemporada: any[] = [
    image_1_2,
    image_2_2,
    image_3_2,
    image_4_2,
    image_5_2,
    image_6_2,
    image_7_2,
    image_8_2,
    image_9_2,
    image_10_2,
    image_11_2,
    image_12_2,
    image_13_2,
    image_14_2,
    image_15_2,
    image_16_2,
    image_17_2,
    image_18_2,
    image_19_2,
    image_20_2,
    image_21_2,
    image_22_2,
    image_23_2,
    image_24_2,
    image_25_2,
    image_26_2,
    image_27_2,
    image_28_2
]

























