import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../views/Home/Home';
import Emissions from '../views/Emissions/Emissions';
import Gallery from '../views/Gallery/Gallery';
import Videos from '../views/Videos/Videos';
import Are from '../views/Are/Are';

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/home" element={<Home />} />
      <Route path="/emisiones" element={<Emissions />} />
      <Route path="/galeria" element={<Gallery />} />
      <Route path="/videos" element={<Videos />} />
      <Route path="/somos" element={<Are />} />
    </Routes>
  );
};

export default AppRoutes;