import React from 'react';
// Styles
import './GalleryImages.scss';
// Data
import { GalleryModel } from '../../types/GalleryModel';


const GalleryImages: React.FC<GalleryModel> = ({images}) => {
    return (
        <section className='container-image-gallery'>
             {images.map((image, index) => (
                <div className='container-image-season'>
                    <img
                        key={index}
                        src={image}
                        alt={`Image ${index + 1}`}
                        style={{
                            gridColumn: index < 13 ? 1 : 2, 
                            gridRow: index < 13 ? index + 1 : index - 12, 
                        }}
                        className={`image_${index + 1}`}
                    />
                </div>
            ))}
        </section>
    )
}

export default GalleryImages;