import React, { useState } from 'react';
// Styles
import './Videos.scss';
// Data
import { titlesSeasonSecond, descriptionSeasonSecond, iframeSeasonSecond} from "../../utils/data/VideosData";
import { titlesSeasonThree, descriptionSeasonThree, iframeSeasonThree } from "../../utils/data/VideosData";
import VideosRoutes from '../../components/VideosRoutes/VideosRoutes';
// Images
import sing from '../../utils/images/components/Letrero_de_Madera.png';

const Videos: React.FC = () => {
  const [season_1, setSeason1] = useState<boolean>(false);
  const [season_2, setSeason2] = useState<boolean>(true);
  const [season_3, setSeason3] = useState<boolean>(false);

  const validateSeason = (season: number): void => {
    if (season === 1) {
      setSeason1(true);
      setSeason2(false);
      setSeason3(false);
    } 
    if (season === 2) {
      setSeason1(false);
      setSeason2(true);
      setSeason3(false);
    }
    if (season === 3) {
      setSeason1(false);
      setSeason2(false);
      setSeason3(true);
    }
  }
  return (
    <section className="videos-container">
      <div className='title-videos'>
        <h1>
          <em>VIDEOS</em>
        </h1>
      </div>
      <div className='seasons-container-videos'>
        {/* <div onClick={() => validateSeason(1)} className='container-season-1-videos'>
          <p>Temporada 1</p>
          <img src={sing} alt="Location 1" />
        </div> */}
        <div onClick={() => validateSeason(2)} className='container-season-2-videos'>
          <p>Temporada 2</p>
          <img src={sing} alt="Location 2" />
        </div>
        <div onClick={() => validateSeason(3)} className='container-season-3-videos'>
          <p>Temporada 3</p>
          <img src={sing} alt="Location 3" />
        </div>
      </div>
      {
        season_2 ? 
        <VideosRoutes title={titlesSeasonSecond} description={descriptionSeasonSecond} iframe={iframeSeasonSecond} /> :
        <VideosRoutes title={titlesSeasonThree} description={descriptionSeasonThree} iframe={iframeSeasonThree} />
      }
    </section>
  );
};

export default Videos;