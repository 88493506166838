import React from "react";
// Data
import videosModel from "../../types/VideosModel";
// Styles
import './VideosRoutes.scss';

const VideosRoutes: React.FC<videosModel> = ({ title, description, iframe }) => {
    return (
        <section className="videosmultiplayer-container">
            {title.map((t, index) => (
                <div className="video-section" key={index}>
                    <p className="title-video">{t}</p>
                    <p className="description-video">{description[index]}</p>
                    {React.createElement("iframe", {
                        width: iframe[index].width,
                        height: iframe[index].height,
                        src: iframe[index].src,
                        title: iframe[index].title,
                        allow: iframe[index].allow,
                        frameBorder: iframe[index].frameBorder,
                        allowFullScreen: iframe[index].allowFullScreen,
                        className: 'frame-video'
                    })}
                </div>
            ))}
        </section>
    );
};



export default VideosRoutes;