import React from "react";
import { useLocation } from 'react-router-dom';
// Styles
import './Footer.scss';
// Images
import alcaldiaBogota from '../../utils/images/components/Logo_ALCALDÍA-BTA_fondo-negro.png';
import pdaLogo from '../../utils/images/components/LOGO-PDE_fondo-negro.png';
const Footer: React.FunctionComponent = () => {
    const location = useLocation();
    return (
        <footer className="footer-container">
            {location.pathname === '/home' || location.pathname === '/' ? (
                <>
                
                <div className="footer-image_alcaldia">
                    <img src={alcaldiaBogota} alt="sponsors" />
                </div>
                <div className="footer-image_pda">
                    <img src={pdaLogo} alt="sponsors" />
                </div>
                </>
            ) : null}
        </footer>
    );
}

export default Footer;