import React from 'react';
// Styles
import './Are.scss';
// Images
import lateralImage from '../../utils/images/components/ramaLateral-l.png';
import logoImage from '../../utils/images/components/logo_periferia_production.jpg';
import periferiaImage from '../../utils/images/components/LogoPeriferia_2.png';
import recorderImage from '../../utils/images/components/recorder_about.png';
import photoImage from '../../utils/images/components/Foto_img.png';

const Are: React.FC = () => {

  return (
    <section className="are-container">
      <div className='background-color'></div>
      <img className='lateral-images-left' src={lateralImage} alt="Lateral Image" />
      <img className='lateral-images-rigth' src={lateralImage} alt="Lateral Image" />
      <div className='title'>
        <h1>
          SOMOS
        </h1>
      </div>
      <img className='logo' src={logoImage} alt="Lateral Image" />
      <img className='periferia' src={periferiaImage} alt="Lateral Image" />
      <img className='recorder' src={recorderImage} alt="Lateral Image" />
      <div className='text'>
        <article className='article-container-are'>
          <p>
            Periferia Producciones es una productora audiovisual, organización de promoción 
            social y artística que nace bajo las instalaciones de la casa mayaelo en Ciudad Bolívar 
            como un ejercicio de impulso de lo que denominamos “Estéticas amarradas con 
            alambres “. cómo construir comunidades a través del arte y la pedagogía, en este 
            ejercicio hemos realizado acciones artísticas, encuentros pedagógicos, que permiten 
            un intercambio de saberes en el marco de las otras estéticas. Tomando como 
            fundamento La cultura y el territorio como un escenario de construcción sensible de y 
            hacia nuestras realidades.
          </p>
          <p>
            Contamos con un historial de ejecución exitosa de proyectos como:
            Por el tunjuelo: proyecto social y documental audiovisual iniciativa ganadora del programa distrital de estímulos 2018,            
            el PDB (plan de desarrollo barrial) beneficiado a través del proyecto Uno +Uno=Todos. 
            Una + Una igual Todas- Fondo de Desarrollo Local de Ciudad Bolívar 2021, práctica de 
            desarrollo sostenible desde las comunidades, programas de formación artística, 
            investigación y aportes a la recuperación del ecosistema subxerofítico presente en 
            gran parte de la localidad. El más importante ejercicio de enunciación lo realizamos 
            consecutivamente y de manera anual articulado con diferentes colectividades y es el 
            Festival Periferia; semana en la que se propone una gran puesta en escena desde y 
            para la comunidad en torno a las artes y elementos propios de contextos de diferentes 
            comunidades. El festival en su esencia comparte con artistas, gestores y actores 
            culturales un espacio de diálogos en torno a lo técnico, lo poético y el compromiso del 
            arte como un transformador tanto de contextos como de vidas.
          </p>
          <img className='image-photo' src={photoImage} alt="Photo" />
          <h3 className='titles-are-mission'>Misión:</h3>
          <p>
            sistematizar la memoria histórica,la riqueza ambiental y cultural, los trabajos y productos 
            artísticos en contextos periurbanos a través de diferentes proyectos de intervención social 
            y artística, promover referentes, experiencias y prácticas artísticas como 
            herramientas,fundamentos y fuentes que posibiliten la transformación social.
          </p>
          <h3 className='titles-are-vision'>Visión:</h3>
          <p>
            Ser una reconocida y confiable fuente de divulgación de contenidos en patrimonio, 
            memoria histórica, arte, cultura, tecnología, entretenimiento, diseño e innovación.
          </p>
          <h3 className='titles-are-targets'>Objetivos:</h3>
          <p>
            sistematizar y construir una memoria colectiva desde las narrativas y manifestaciones 
            sociales, artísticas y culturales a través de actores o agentes territoriales por medio de la 
            producción transmedia principalmente a manera de magazine.
          </p>
        </article>
      </div>
    </section>
  );
};

export default Are;