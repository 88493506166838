import React from "react";
import { useNavigate } from 'react-router-dom';
// Styles
import '../../styles/global.scss'
import './Header.scss';
// Images
import logo from '../../utils/images/logo.png';
import culturaBogota from '../../utils/images/components/cultura-bogota-byn.png';

const Header: React.FC = () => {

    const navigate = useNavigate();
    
    const goTo : (view: string) => void = (view) => {
        navigate(`/${view}`);
    };

    return (
        <header className="header-container">
            <section className="header-section-container">
                <div className="header-image_cultura">
                    <img src={culturaBogota} alt="sponsors" />
                </div>
                <img onClick={() => goTo('home')} className="header-section-image" src={logo} alt="header logo" />
            </section>
        </header>
    );
}

export default Header;