import React, { useState } from 'react';
import GalleryImages from '../../components/GalleryImages/GalleryImages';
// Styles
import './Gallery.scss';
// Images
import location_1 from '../../utils/images/components/location1.jpg';
import location_2 from '../../utils/images/components/location2.jpg';
import location_3 from '../../utils/images/components/location3.jpg';
import sing from '../../utils/images/components/Letrero_de_Madera.png';
// Data
import { PrimeraTemporada, SegundaTemporada } from '../../utils/data/GalleryData';


const Gallery: React.FC = () => {

  const [season_1, setSeason1] = useState<boolean>(true);
  const [season_2, setSeason2] = useState<boolean>(false);

  const validateSeason = (season: number): void => {
    if (season === 1) {
      setSeason1(true);
      setSeason2(false);
    } 
    if (season === 2) {
      setSeason1(false);
      setSeason2(true);
    }
  }

  return (
    <section className="gallery-container">
      <div className='title-gallery'>
        <h1>
          <em>GALERIA</em>
        </h1>
      </div>
      <article className='radio-text'>
        <h2>Radio la terraza</h2>
        <p>
          Es un ejrcicio artistico a modo de intervencion, haciendo de la casa de paroy y zinc, un módulo 
          arquitectonico con estos elementos que estructuran la memoria del entomos que habitamos y por ello 
          los convertimos en nuestro sed, espacio donde entrevistamos diferentes procesos esteticos reales, 
          propios, que buscan introducirse en memorias, la emisora "LA TERRAZA" es un magazin que busca 
          promover la producción de contenido academico, artistico, ambiental y social a travez de las voces de 
          los artistas, colectivos, lideres, actores culturales, entre otros, cuyo resultado sera sistematizado en la 
          producción de programas transmedia ( audiovisual y podcast ) producidos en un en vivo a traves de 
          plataforma web, desde terrazas como espacios no convencionales en contextos perifericos de la 
          localidad que son posteriormente editados para construir un archivo que recompila lo mejor de los 
          contextos periurbanos.
        </p>
      </article>
      <div className='images-container'>
        <img src={location_1} alt="Location 1" />
        <img src={location_2} alt="Location 2" />
        <img src={location_3} alt="Location 3" />
      </div>
      <div className='seasons-container'>
        <div onClick={() => validateSeason(1)} className='container-season-1'>
          <p>Temporada 1</p>
          <img src={sing} alt="Location 1" />
        </div>
        <div onClick={() => validateSeason(2)} className='container-season-2'>
          <p>Temporada 2</p>
          <img src={sing} alt="Location 2" />
        </div>
        <div onClick={() => validateSeason(3)} className='container-season-3'>
          <p>Temporada 3</p>
          <img src={sing} alt="Location 3" />
        </div>
      </div>
      <article className='season-text'>
        <h4>
          { season_1 ? 'Temporada 1 - Festival periferia "Emisora la terraza".' :  'Temporada 2 - Festival periferia "Emisora la terraza".'}
        </h4> 
        <p>
          { season_1 ? 
          `La primer experiencia de nuestra emisora se lanzó bajo las ventajas del libro "esteticas amarradas con 
          alambres" antecedentes que nos sirvio para vincular la 6ta version del festival de los artes periferia a 
          travez de de una emisora inherante que abordo 15 programas a manera de magazin audiovisual 
          transmitido por plataformas digitales donde los artes, artistas y narrativas de los y las participantes se 
          involuclaron en a la memoria historica y las esteticas propias de la localidad 19 de ciudad Bolívar. 
          Iniciativa ganadora de "Beca para la realiazcion de eventos artisticos y culturales en la localidad 
          de Ciudad Bolivar". 2021.`
          : null }
        </p>
      </article>
      <GalleryImages images={season_1 ? PrimeraTemporada : SegundaTemporada}/>
    </section>
  );
};

export default Gallery;